import Color from 'color'

const createColorScheme = ({ color = '#330066', count }) => {
  color = Color(color)
  const colors = [color.hex()]

  const saturationStep = 50 / count
  const lightnessStep = (100 - color.lightness()) / count

  let saturation = color.saturationl()
  let lightness = color.lightness()

  for (let i = 1; i < count; i++) {
    if (lightness < 50) {
      lightness = lightness + lightnessStep
    } else {
      lightness = lightness + lightnessStep
      saturation = saturation - saturationStep
    }

    color = color.saturationl(saturation).lightness(lightness)
    colors.push(color.hex())
  }

  return colors
}

/**
 * @description Create Echarts chart options for the given metric type
 * @param {String} metricType
 */
const createChartOptions = (colorCount) => {
  return { color: createColorScheme({ count: colorCount }) }
}

export { createChartOptions, createColorScheme }
