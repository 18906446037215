import type { FeatureCollection } from 'geojson'
import { acceptHMRUpdate, defineStore } from 'pinia'

import { keyedBy } from '~/helpers/keyedBy.ts'
import { type CountryInformation } from '~/types.ts'

export interface Country {
  name: string
  alpha_2: string
  alpha_3: string
  aliases: string[]
  capital: string
  subdivision_names: string[]
}

export const useCountries = defineStore('countries', () => {
  const countries = reactive<Record<string, Country>>({})
  const information = ref<Record<string, CountryInformation>>({})
  let informationVersion = 0

  const geoJSON = ref<FeatureCollection>({
    features: [],
    type: 'FeatureCollection',
  })

  const countriesByAlpha2Code = computed(() => keyedBy('alpha_2', Object.values(countries), (alpha2) => alpha2.toLowerCase()))
  const countriesByAlpha3Code = computed(() => keyedBy('alpha_3', Object.values(countries), (alpha3) => alpha3.toLowerCase()))
  const countriesByCapital = computed(() => keyedBy('capital', Object.values(countries), (capital) => capital.toLowerCase()))

  const richFlagStyles = computed(() => {
    const rich = {} as Record<string, any>
    for (const country of Object.values(countries)) {
      const countryCode = country.alpha_2.toLowerCase()
      const url = `/flags/${countryCode}.svg`
      rich[country.alpha_3] = {
        height: 20,
        width: 30,
        align: 'left',
        backgroundColor: {
          image: url,
        },
      }
    }

    return rich
  })

  function country(name: string): Country | null {
    if (!name) {
      return null
    }

    name = name.toLowerCase()
    if (name in countries) {
      return countries[name]
    }

    if (name in countriesByAlpha2Code.value) {
      return countriesByAlpha2Code.value[name]
    }

    if (name in countriesByAlpha3Code.value) {
      return countriesByAlpha3Code.value[name]
    }

    if (name in countriesByCapital.value) {
      return countriesByCapital.value[name]
    }

    for (const key in countries) {
      const country = countries[key]
      if (country.subdivision_names.includes(name)) {
        return country
      }
      if (country.aliases.includes(name)) {
        return country
      }
    }

    return null
  }

  function countryName(value: string): string | null {
    return country(value)?.name ?? null
  }

  function queryCountries(query: string, cb?: (results: { value: string; code: string }[]) => void) {
    if (!query) {
      const results: { value: string; code: string }[] = []
      for (const key in countries) {
        const country = countries[key]
        results.push({ value: country.name, code: country.alpha_2 })
      }
      if (cb) {
        return cb(results)
      }

      return results
    }

    query = query.toLowerCase()

    const results = []
    for (const [key, country] of Object.entries(countries)) {
      const result = { value: country.name, code: country.alpha_2 }

      if (key.includes(query)) {
        results.push(result)
      } else if (country.alpha_3.toLowerCase().includes(query)) {
        results.push({ ...result, label: country.alpha_3 })
      } else if (country.capital.toLowerCase().includes(query)) {
        results.push({ ...result, label: country.capital })
      }
    }

    if (cb) return cb(results)

    return results
  }

  function searchGeoJSON(name: string) {
    return geoJSON.value.features.filter((feature) => feature.properties?.name === name)
  }

  async function fetchInformation() {
    if (informationVersion) return

    const { data } = await api.get<{ hits: Record<string, CountryInformation> }>('country-information')

    if ('hits' in data) {
      information.value = Object.freeze(data.hits)
      informationVersion = Object.values(information.value).find((hit) => hit.cpi)?.cpi.year ?? 0
    }
  }

  async function fetchGeoJSON() {
    if (Object.keys(geoJSON.value.features).length > 0) {
      return
    }

    const { data } = await web.get<FeatureCollection>(`countries.geojson?${$env.version}`)
    geoJSON.value = Object.freeze(data)
  }

  async function fetchCountries() {
    const { data } = await api.get('countries')
    Object.assign(countries, Object.freeze(data))
  }
  fetchCountries()

  return {
    countries,
    countriesByAlpha2Code,
    countriesByAlpha3Code,
    geoJSON,
    country,
    countryName,
    information,
    queryCountries,
    richFlagStyles,
    searchGeoJSON,
    fetchInformation,
    fetchGeoJSON,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCountries, import.meta.hot))
}
