import { acceptHMRUpdate, defineStore } from 'pinia'

import router, { route } from '~/router.ts'
import { type OrganizationService, type OrganizationServiceName } from '~/types.ts'

export const useServices = defineStore('services', {
  state: (): Partial<Record<OrganizationServiceName, OrganizationService>> => ({}),

  actions: {
    load(services: OrganizationService[]) {
      for (const service of services) {
        this.$state[service.name] = service
      }
    },

    set(services: OrganizationService[]) {
      for (const key in this.$state) {
        delete this.$state[key as OrganizationServiceName]
      }
      this.load(services)

      if (route.value.meta.service && !(route.value.meta.service in this.$state)) {
        router.replace('/')
      }
    },

    async add(name: OrganizationServiceName) {
      try {
        const { data } = await api.post<OrganizationService>(`organization-services/${stores.organization.id}`, { name })
        this.$state[name] = data
        $message.success($t('successfully-added-the-service'))
      } catch {
        $message.error($t('api-error'))
      }
    },

    async remove(name: OrganizationServiceName) {
      const service = this.$state[name]
      if (!service) {
        return
      }

      try {
        await api.delete(`organization-services/${service.id}`)
        delete this.$state[name]
        $message.success($t('successfully-removed-the-service'))
      } catch {
        $message.error($t('api-error'))
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useServices, import.meta.hot))
}
