<template>
  <VBadge v-if="placeholder" :hidden="hideOnlineStatus || !$stores.users.onlineUsers.has(user.id)" :size="Math.round(size / 6)" type="green">
    <VAvatar :placeholder :seed="userId" :size :src />
  </VBadge>
</template>

<script setup lang="ts">
interface Props {
  userId: number
  size?: number
  hideSrc?: boolean
  hideOnlineStatus?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  size: 26,
  loading: undefined,
})

const user = computed(() => {
  const userId = props.userId
  if (userId === stores.user.user.id) {
    return stores.user.user
  }

  return stores.users.users[props.userId] ?? ('deletedUsers' in stores.users ? stores.users.deletedUsers[props.userId] : undefined)
})
const placeholder = computed(() => (user.value ? `${user.value.first_name.charAt(0)}${user.value.last_name.split(' ').pop()?.charAt(0)}` : ''))
const src = computed(() => (props.hideSrc ? '' : version.value ? `api/avatars/${props.userId}?v=${version.value}` : ''))
const version = computed(() => stores.avatars.avatars[props.userId]?.version)

stores.avatars.fetch()
</script>

<style type="scss" scoped>
.v-badge.has-slot :deep() .v-badge__content {
  bottom: -8px;
  right: 8px;
  top: unset;
  border: 2px solid #fff;
}
</style>
