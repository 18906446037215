import { acceptHMRUpdate, defineStore } from 'pinia'

import { type IntelligentCheckJob } from '~/types.ts'

export const useRunningIntelligentCheckJobs = defineStore('runningIntelligentCheckJobs', () => {
  const runningIntelligentCheckJobs = ref<Record<number, Record<string, Set<number>>>>({})

  function add({ caseId, type, filteredSourceIds }: IntelligentCheckJob) {
    runningIntelligentCheckJobs.value[caseId] ??= {}
    runningIntelligentCheckJobs.value[caseId][type] = new Set([...(runningIntelligentCheckJobs.value[caseId][type] ?? []), ...filteredSourceIds])
  }

  function remove(data: IntelligentCheckJob) {
    if (!(data.caseId in runningIntelligentCheckJobs.value && data.type in runningIntelligentCheckJobs.value[data.caseId])) {
      return
    }

    if (!data.filteredSourceIds.length) {
      delete runningIntelligentCheckJobs.value[data.caseId][data.type]

      return
    }
    for (const sourceId of data.filteredSourceIds) {
      runningIntelligentCheckJobs.value[data.caseId][data.type].delete(sourceId)
    }

    if (runningIntelligentCheckJobs.value[data.caseId][data.type].size === 0) {
      delete runningIntelligentCheckJobs.value[data.caseId][data.type]
    }
    if (Object.keys(runningIntelligentCheckJobs.value[data.caseId]).length === 0) {
      delete runningIntelligentCheckJobs.value[data.caseId]
    }
  }

  return {
    runningIntelligentCheckJobs,
    add,
    remove,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRunningIntelligentCheckJobs, import.meta.hot))
}
