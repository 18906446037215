import { acceptHMRUpdate, defineStore } from 'pinia'

let fetched = false
let fetching = false

export const useFlags = defineStore('flags', {
  state: () => ({
    perSource: {} as Record<string, string>,
  }),

  actions: {
    async fetch() {
      if (fetched || fetching) {
        return
      }
      fetching = true

      try {
        const { data } = await api.get<{ flag_code: string; source: string }[]>('flags')
        for (const item of data) {
          this.perSource[item.source] = item.flag_code
        }
        fetched = true
      } catch {
        $message($t('api-error'))
      }

      fetching = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFlags, import.meta.hot))
}
