import { acceptHMRUpdate, defineStore } from 'pinia'

let fetched = false
let fetching = false

export const useArchivedSources = defineStore('archivedSources', {
  state: () => ({
    perType: {} as Record<string, string[]>,
  }),

  actions: {
    async fetch() {
      if (fetched || fetching) {
        return
      }
      fetching = true

      try {
        const { data } = await api.get<Record<string, string[]>>('archived-sources')
        for (const [type, sources] of Object.entries(data)) {
          this.perType[type] = sources.map((source) => source.toLowerCase())
        }
        fetched = true
      } catch {
        $message($t('api-error'))
      }

      fetching = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useArchivedSources, import.meta.hot))
}
