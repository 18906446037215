import type { Dayjs } from '@vartion/ui'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useDateOptions = defineStore('dateOptions', () => {
  const options = computed((): { label: string; value: [Dayjs, Dayjs] }[] => {
    return [
      { label: $t('previous-year'), value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')] },
      { label: $t('last-count-days', 365), value: [dayjs().subtract(365, 'day'), dayjs()] },
      { label: $t('this-year'), value: [dayjs().startOf('year'), dayjs().endOf('year')] },
      { label: $t('last-count-days', 31), value: [dayjs().subtract(31, 'day'), dayjs()] },
      { label: $t('this-month'), value: [dayjs().startOf('month'), dayjs().endOf('month')] },
      { label: $t('last-count-days', 7), value: [dayjs().subtract(7, 'day'), dayjs()] },
      { label: $t('this-week'), value: [dayjs().startOf('week'), dayjs().endOf('week')] },
      { label: $t('today'), value: [dayjs(), dayjs()] },
    ]
  })

  return {
    options,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDateOptions, import.meta.hot))
}
