import { Userpilot } from 'userpilot'

import { APP_ROLES } from '~/stores/roles.ts'
import { type CaseSourceName, type Env, type MonitoringFrequency, type UserDetailed } from '~/types.ts'

function formatMonitoringFrequency(frequency: MonitoringFrequency) {
  switch (frequency) {
    case 0:
      return 'No monitoring'
    case 1:
      return 'Daily'
    case 7:
      return 'Weekly'
    case 28:
      return 'Monthly'
    case 365:
      return 'Yearly'
    default:
      return ''
  }
}

export const userpilotClient = {
  env: {} as Env['userpilot'],

  setUser(user: UserDetailed) {
    if (Object.keys(this.env).length === 0) {
      throw Error('Please make sure that userpilot is initialized first!')
    }

    const data: Record<string, any> = {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      API: user.tokens_count > 0,
      company: {
        id: `${$env.app_name} ${stores.organization.id}`,
        name: stores.organization.name,
        Type: stores.organization.type,
        Origin: stores.organization.origin,
        'Trial end date': stores.organization.trial_ends_at,
        'Pascal screening': stores.organization.has_screening,
        'Pascal onboarding': stores.organization.has_onboarding,
        Consent: stores.organization.account_access_consent,
        HubSpot: stores.organization.has_hubspot,
      },
    }

    const activeSources = new Set([...stores.dataSources.activeSources.Person, ...stores.dataSources.activeSources.Business, ...stores.dataSources.activeSources.Asset])
    for (const [source, label] of Object.entries(stores.source.labels) as [CaseSourceName, string][]) {
      if (activeSources.has(source)) {
        data.company[label] = formatMonitoringFrequency(stores.policies.policies[`monitoring_frequency_${source}`])
      } else {
        data.company[label] = 'Not enabled'
      }
    }

    for (const role of APP_ROLES) {
      data[role] = user.roles.some((userRole) => userRole.name === role)
    }

    Userpilot.identify(`${$env.app_name} ${user.id}`, data)
    Userpilot.reload()
  },

  run(env: Env['userpilot']) {
    this.env = env

    if (env.token) {
      Userpilot.initialize(env.token)
    }
  },

  reload() {
    Userpilot.reload()
  },
}
