import { acceptHMRUpdate, defineStore } from 'pinia'

interface Response {
  LOCATION: string[][]
  ORGANIZATION: string[][]
  PERSON: string[][]
}

export const useNER = defineStore('ner', {
  state: () => ({
    results: {} as Record<string, string>,
    queries: {} as Record<string, Record<string, string>>,
  }),

  actions: {
    async search(entity: string) {
      const lowercase = entity.toLowerCase()
      if (lowercase in this.results) {
        return this.results[lowercase]
      }

      await this.ner(capitalize(entity))

      if (lowercase in this.results) {
        return this.results[lowercase]
      }

      return ''
    },

    async ner(text: string) {
      if (text in this.queries) {
        return this.queries[text]
      }

      const { data } = await api.post<Response>('ner', { text }, { key: text })

      const results: Record<string, string> = {}
      for (const [type, entities] of Object.entries(data)) {
        for (const entity of entities) {
          results[entity.join(' ').toLowerCase()] = type.toLowerCase()
        }
      }

      this.results = { ...this.results, ...results }
      this.queries[text] = results

      return results
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNER, import.meta.hot))
}
