import type { EChartsOption } from 'echarts'

import { createChartData } from './createChartData.ts'
import { createChartOptions, createColorScheme } from './createChartOptions.ts'

export const colors = createColorScheme({ count: 20 })

export function formatLabel(value) {
  const max = 20

  if (value.length > max) {
    return `${value.slice(0, max)}...`
  }

  return value
}

export const defaultChartOptions = {
  axisLabel: { fontWeight: 'lighter', color: '#333' },
  axis: { fontWeight: 'lighter', color: '#333', axisTick: { show: false }, axisLine: { show: false } },
  grid: { top: '5%', left: '2%', right: '2%', bottom: '5%', containLabel: true },
  tooltip: { transitionDuration: 0, confine: true },
}

export function options(options: EChartsOption, type: '' | 'bar' | 'donut' | 'line' | 'scatter' = '', categoriesCount = 20, color = '#330066', otherColor = '') {
  const { axisLabel, axis, tooltip } = defaultChartOptions
  const grid = { ...defaultChartOptions.grid, ...options.grid }

  const colors = createColorScheme({ color, count: categoriesCount })
  if (otherColor) {
    colors[colors.length - 1] = otherColor
  }
  options = {
    color: colors,
    textStyle: { fontFamily: 'TTCommons, Helvetica, Arial, sans-serif' },
    tooltip,
    ...options,
  }

  if (type === 'bar') {
    options.grid = grid
    options.xAxis = { ...axis, type: 'value', axisLine: { lineStyle: { color: '#ccc' } }, splitLine: { show: false }, ...options.xAxis }
    options.yAxis = { ...axis, type: 'category', axisLabel: { ...axisLabel, formatter: formatLabel }, ...options.yAxis }
  }

  if (type === 'donut') {
    options.legend.formatter = formatLabel
  }

  if (type === 'line') {
    options.grid = grid
    options.xAxis = { ...axis, type: 'category', ...options.xAxis }
    options.yAxis = { ...axis, ...options.yAxis }

    if (!('animationDuration' in options)) {
      options.animationDuration = 1000
    }
  }

  if (type === 'scatter') {
    options.grid = grid
    options.xAxis = { ...axis, type: 'category', splitLine: { show: true, lineStyle: { color: '#ccc', type: 'dashed' }, ...options.xAxis } }
    options.yAxis = { ...axis, inverse: true, ...options.yAxis }
    options.tooltip = {
      ...options.tooltip,
      position: 'top',
      formatter: (params) => {
        const hour = dayjs(params.value[0], 'H').format('HH')

        return `${params.value[1]} ${hour}:00: ${params.value[2]}`
      },
    }
  }

  return options
}

export { createChartData, createChartOptions }
