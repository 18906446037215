import {
  type AssetForm,
  type BusinessForm,
  type CaseModel,
  type DataSource,
  type FormattedCaseModel,
  type Meta,
  type OrganizationWithBillingSettingsAndPolicies,
  type PersonForm,
  type UserPivot,
} from '~/types.ts'

export function scoreColor(score: number, format = 'hsl') {
  if (format === 'hsl') {
    const color = 120 * (score / 100)

    return `hsl(${color}, 80%, 50%)`
  }

  const red = score < 50 ? 255 : Math.floor(255 - ((score * 2 - 100) * 255) / 100)
  const green = score > 50 ? 255 : Math.floor((score * 2 * 255) / 100)

  return `rgb(${red}, ${green}, 0)`
}

export function formatCountry(code: string) {
  return code ? stores.countries.countriesByAlpha3Code[code.toLowerCase()].name : ''
}

// We cannot use \b word boundary because it does not work with for example cyrillic characters.
export function escapeRegExp(string: string) {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export const wordBoundaries = {
  before: '(^|[\\s])',
  after: '(?=[\\s,.’\'”"!?:]|$)',
}

const tokenCharactersRegex = /[^' ',\-/.]/
export function containsOnlyTokenCharacters(string: string) {
  if (string.length === 0) {
    return false
  }

  return !tokenCharactersRegex.test(string)
}

export function createHighlightQueries(form: Partial<PersonForm & BusinessForm & AssetForm>, field: string): string | string[] | RegExp[] {
  if (Object.keys(form).length === 0) {
    return []
  }

  if (field === 'gender') {
    // Gender is not in form in business check
    return form.gender ? [new RegExp(`${wordBoundaries.before}${escapeRegExp(form.gender)}${wordBoundaries.after}`, 'gmi')] : []
  }

  let items: (string | undefined | null)[] = []

  const aliases = form.aliases ?? []
  const additionalTerms = form.additional_terms ?? []

  if (['name', 'alternative_names', 'aliases', 'persons', 'organizations', 'locations', 'summary', 'title'].includes(field)) {
    items = [form.name, ...aliases, ...additionalTerms]
  } else if (field === 'company_number') {
    items = [form.company_number]
  } else if (field === 'birth_dates') {
    items = [formatDate(form.date_of_birth)]
  } else if (field === 'country') {
    items = [formatCountry(form.country ?? '')]
  } else if (field === 'nationalities') {
    items = [...(form.nationalities ?? []).map((nationality: string) => formatCountry(nationality)), formatCountry(form.country_of_birth ?? ''), ...additionalTerms]
  } else if (field === 'birth_places' || field === 'country_of_birth') {
    items = [...(form.nationalities ?? []).map((nationality: string) => formatCountry(nationality)), formatCountry(form.country_of_birth ?? '') ?? '', ...additionalTerms]
  } else if (field === 'addresses') {
    items = [
      ...(form.nationalities ?? []).map((nationality: string) => formatCountry(nationality)),
      formatCountry(form.country_of_birth ?? '') ?? '',
      form.address,
      formatCountry(form.country_of_residence ?? ''),
      ...additionalTerms,
    ]
  } else if (field === 'company_address') {
    items = [form.address, formatCountry(form.country_of_residence ?? ''), ...additionalTerms]
  } else if (field === 'identifiers') {
    items = [form.identifier]
  } else if (
    ['source', 'sanction_type', 'url', 'measures', 'reason', 'program', 'characteristics', 'occupation', 'connections', 'further_information', 'worldcheck_categories'].includes(
      field,
    )
  ) {
    items = additionalTerms.slice()
    if (form.identifier) {
      items.push(form.identifier)
    }
  }

  const stringItems = items.filter((item) => !!item) as string[]

  return stringItems.map((item) => new RegExp(`${wordBoundaries.before}${escapeRegExp(item)}${wordBoundaries.after}`, 'gmi'))
}

export function adverseTags(data: object) {
  const adverse = stores.initialState.adverse

  const tags = []

  for (const [key, value] of Object.entries(data ?? {})) {
    if (key !== 'narcotics' && value >= adverse.minimum_threshold) {
      tags.push($t(adverse.labels[key] ?? kebab(key)))
    }
  }

  return tags
}

interface Component {
  [key: string]: any
  $children: Component[]
  $options: { [key: string]: any; name: string }
}
export function findChildComponent(component: Component, childName: string): Component | null {
  for (const child of component.$children) {
    if (child.$options.name === childName) {
      return child
    }

    const childComponent = findChildComponent(child, childName)

    if (childComponent) {
      return childComponent
    }
  }

  return null
}

export function blurAll() {
  const tmp = document.createElement('input')
  document.body.appendChild(tmp)
  tmp.focus()
  document.body.removeChild(tmp)
}

export function dataSourceVendor(dataSourceId: number) {
  const dataSource = stores.dataSources.dataSources.find((dataSource: DataSource) => dataSource.id === dataSourceId)

  if (dataSource) {
    return dataSource.marketplace_pack?.vendor ?? $t('data-directory')
  }

  return $t('not-available')
}

export interface Assignee {
  type: 'user' | 'group'
  value: number | null
}

/**
 * Expanded type for case and policy configuration. Actual `CaseModel` objects do not have this.
 */
export interface AssigneeOrSelf {
  type: 'user' | 'group' | 'self'
  value: number | null
}

export function toAssignee(caseModel: CaseModel): Assignee {
  if (caseModel.user_id) {
    return { type: 'user', value: caseModel.user_id }
  }

  return { type: 'group', value: caseModel.group_id }
}

type RecursiveArray = (RecursiveArray | string | object)[]
export function stringInArray({ array, string, depth = 0, maxDepth = 5 }: { array: RecursiveArray; string: string; depth?: number; maxDepth?: number }): boolean {
  string = string.toLowerCase()

  return array.some((value) => {
    if (typeof value === 'string') {
      return value.toLowerCase().includes(string)
    } else if (Array.isArray(value) && depth < maxDepth) {
      return stringInArray({ array: value, string, depth: depth + 1 })
    } else if (typeof value === 'object' && value !== null && depth < maxDepth) {
      return stringInArray({ array: Object.values(value), string, depth: depth + 1 })
    }

    return false
  })
}

export function getDomain(url: string | URL) {
  return new URL(url).hostname
}

export async function logout() {
  await web.post('logout')
  window.location.replace(window.location.origin)
}

export function formatCase(item: CaseModel): FormattedCaseModel {
  const formatted: FormattedCaseModel = Object.assign(
    {
      assignee: 'Unassigned',
      group: 'Unassigned',
      riskLabel: null,
    },
    item,
  )

  const user = stores.users.users[item.user_id ?? -1]
  if (user) {
    formatted.assignee = `${user.first_name} ${user.last_name}`
  }

  const group = stores.groups.groups[item.group_id ?? -1]
  if (group) {
    formatted.group = group.name
  }
  formatted.riskLabel = getHighestRisk(item.risk)

  return formatted
}

export function createMeta(): Meta {
  return {
    current_page: 1,
    last_page: 1,
    total: 0,
    per_page: 10,
    from: 0,
    to: 10,
  }
}

export function openExternal(url: string) {
  const a = document.createElement('a')
  a.href = `/redirect/${url}`
  a.target = '_blank'
  a.rel = 'noopener'
  a.click()
  a.remove()
}

export function isOrganizationValid(organization: OrganizationWithBillingSettingsAndPolicies & { pivot: UserPivot }) {
  const daysUntilInactive = stores.initialState.gracePeriod.days_until_inactive
  const now = dayjs()
  if (!organization.pivot.active || organization.pivot.deleted_at) {
    return false
  }

  if (organization.type === 'developer') {
    return true
  }
  if (organization.type === 'free') {
    return true
  }

  if (
    organization.invalid_payment_method_at &&
    (now.isBefore(dayjs(organization.created_at).add(daysUntilInactive, 'days')) || dayjs(organization.invalid_payment_method_at).add(daysUntilInactive, 'days').isBefore(now))
  ) {
    return false
  }

  if (organization.type === 'trial' && (!organization.billing_settings?.id || organization.invalid_payment_method_at)) {
    return false
  }

  return true
}

export function isOneTimeCase() {
  return !!api.headers.Authorization
}

export function setDocumentTitle(title: string) {
  document.title = `${title} - ${$env.app_name ?? 'Pascal'}`
}

export function isAbortError(error: unknown) {
  if (error instanceof DOMException && error.name === 'AbortError') {
    return true
  }

  if (typeof error === 'string' && error.startsWith('Request aborted due to a new request with the same fingerprint')) {
    return true
  }

  return false
}

export function getWeekDays(): { value: number; label: string }[] {
  const days = []

  for (let index = 1; index <= 7; index++) {
    days.push({
      value: index,
      label: capitalizeFirst(dayjs().isoWeekday(index).format('dddd')),
    })
  }

  return days
}
