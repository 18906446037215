export function keyedBy<T extends Record<string, any>[]>(key: keyof T[number], array: T, transform?: (key: string) => string): Record<string, T[number]> {
  const result: Record<string, T[number]> = {}

  for (const object of array) {
    if (transform) {
      result[transform(object[key as string])] = object
      continue
    }

    result[object[key as string]] = object
  }

  return result
}
