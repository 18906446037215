import { type Role, type User } from '~/types.ts'

const permissions = computed(() => {
  return getPermissions(stores.user.user.roles)
})

function getPermissions(roles: Role[]) {
  const names = new Set<string>()

  for (const role of roles) {
    for (const permission of role.permissions) {
      names.add(permission.name)
    }
  }

  return names
}

export function can(permissionName: string, user?: User) {
  if (!user) {
    return permissions.value.has(permissionName)
  }

  return getPermissions(user.roles).has(permissionName)
}

export function hasRole(roleName: string) {
  return stores.user.user.roles.some((role) => role.name === roleName)
}

const mapPermissions: Record<string, string> = {
  'administrations.organizations.view': 'View organisations',
  'administrations.organizations.edit': 'Edit organisations',
  'administrations.users': 'View/Edit users',
  'administrations.cases': 'View/Edit cases',
  'administrations.clients': 'View/Edit clients',
  'administrations.billing': 'View/Edit billing',
  'administrations.engagement': 'View/Edit engagement',
  'administrations.usage': 'View/Edit usage',
  'administrations.logs': 'View/Edit logs',
  'administrations.commands': 'View/Edit commands',
  'administrations.authorization': 'View/Edit authorizations',
}

export function formatPermissionName(name: string) {
  if (name in mapPermissions) {
    return mapPermissions[name]
  }

  name = name.replace('administrations.', '').replaceAll('.', ' ')

  return `${capitalizeFirst(title(kebab(name)).toLowerCase())}`
}
