import { acceptHMRUpdate, defineStore } from 'pinia'

interface Avatar {
  user_id: number
  version: number
}

type Avatars = Record<number, Avatar>

export const useAvatars = defineStore('avatars', {
  state: () => ({
    avatars: {} as Avatars,
    loading: false,
    loaded: false,
  }),

  actions: {
    async fetch() {
      if (this.loaded || this.loading) {
        return
      }

      await this.refresh()
    },

    async refresh() {
      this.loading = true
      const { data } = await api.get<Avatars>('avatars')
      this.avatars = data
      this.loading = false
      this.loaded = true

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAvatars, import.meta.hot))
}
