import { api, http, web } from './services/http.ts'

window.web = web
window.http = http
window.api = api

window.initialStatePromise = api.get('initial-state')

window.nextFrame = nextFrame
window.delay = delay

window.copyObject = (obj) => JSON.parse(JSON.stringify(obj))
