import { acceptHMRUpdate, defineStore } from 'pinia'

import { can } from '~/helpers/permissions.ts'
import router, { route } from '~/router.ts'
import { Cache } from '~/services/cache/Cache.ts'
import { createEcho } from '~/services/echo.ts'
import { notifications } from '~/services/notifications.ts'
import { sentry } from '~/services/sentry.ts'
import { userpilotClient } from '~/services/UserpilotClient.ts'
import type { AtLeast, CaseModel, FormattedCaseModel, Group, Settings, User, UserDetailed } from '~/types.ts'

interface UpdatePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export const useUser = defineStore('user', {
  state: () => ({
    user: {
      id: 0,
      organization_id: 0,
      email: '',
      name: '',
      first_name: '',
      last_name: '',
      function: '',
      active: true,
      created_at: '',
      updated_at: '',
      groups: [],
      roles: [],
      organizations: [],
      password_reset_tokens: [],
      tokens_count: 0,
    } as UserDetailed,
    authenticated: false,
    sessionId: '',
    unreadNotificationsCount: 0,
    unresolvedMonitoredCasesCount: 0,
    expiredPassportsCount: 0,
  }),

  actions: {
    async login() {
      await this.load()
      await stores.organization.load()

      // On login, after user is sucessfully loaded, check if we need to redirect to a different page.
      if (route.value.query?.next) {
        await router.push(route.value.query.next as string)
      } else if (route.value.meta.guest) {
        const app = await Cache.tags('UIState').get('app')
        if (app === 'spectrum' && can('administrations.view')) {
          await router.replace('/administration/home')
        } else if (app === 'onboarding' && stores.organization.has_onboarding) {
          await router.replace('/client-onboarding/home')
        } else {
          await router.replace('/')
        }
      }
    },

    async load() {
      const { data: user } = await api.get<UserDetailed & { settings: Settings }>('user')

      this._update(user)
      await stores.settings.load(user.settings)
      this.authenticated = true
      sentry.setUser(user)

      window.echo = createEcho()
      window.privateChannel = echo.private(`App.Models.User.${user.id}`)

      notifications.listen(window.privateChannel)
      notifications.onQueue()

      privateChannel.listen('UserUpdated', ({ user }: { user: User }) => {
        this._update(user)
        stores.users._update(user)

        const permission = router.currentRoute.value.meta.permission
        if (permission && !can(permission)) {
          router.replace('/')
        }
      })
      privateChannel.listen('InvalidatedSession', () => this.checkLoggedIn())
      privateChannel.listen('LoggedOut', () => this.checkLoggedIn())
      privateChannel.listen('LoggedIn', ({ session_id: sessionId }: { session_id: string }) => {
        if (Number(sessionId) !== stores.initialState.sessionId) {
          $message($t('you-logged-in-in-another-session'))
        }
      })
    },

    async refresh() {
      const { data } = await api.get(`users/${this.user.id}`)

      this._update(data)

      userpilotClient.setUser(data)

      if (route.value.meta.permission && !can(route.value.meta.permission)) {
        await router.replace('/')
      }
    },

    async update(user: AtLeast<User, 'id'>) {
      const { data } = await api.patch(`users/${user.id}`, user)
      this._update(user)
      stores.users._update(data)
    },

    async updatePassword(request: UpdatePasswordRequest) {
      await api.post<User>('passwords', request)
    },

    _update(user: Partial<User>) {
      Object.assign(this.user, user)
    },

    async checkLoggedIn() {
      try {
        await api.get(`users/${this.user.id}`)

        $message($t('you-were-logged-out-in-another-session'))
      } catch {
        window.location.replace(window.location.origin)
      }
    },
  },
})

export function canReadCase(caseModel: CaseModel) {
  if (can('administrations.view') || can('cases.view')) {
    return true
  }

  return canUseCase(caseModel, stores.policies.policies.read_cases)
}

export function canEditCase(caseModel: CaseModel) {
  if (caseModel.status === 'Archived' || caseModel.status === 'Preview' || caseModel.deleted_at || app.config.globalProperties.$inSafeGhostMode) {
    return false
  }

  if (can('administrations.organizations.edit')) {
    return true
  }

  if (isOneTimeCase()) {
    // When we use an api token, we should always be on the one-time-case route.
    return true
  }

  return canUseCase(caseModel, stores.policies.policies.edit_cases)
}

export function canEditCaseStatus(caseModel: CaseModel) {
  if (app.config.globalProperties.$inSafeGhostMode) {
    return false
  }

  if (caseModel.deleted_at) {
    return false
  }

  if (can('administrations.organizations.edit')) {
    return true
  }

  return canUseCase(caseModel, stores.policies.policies.edit_cases)
}

export function canAssignCase(caseModel: CaseModel | FormattedCaseModel): boolean {
  if (app.config.globalProperties.$inSafeGhostMode) return false
  if (!caseModel) return false
  if (caseModel.status === 'Archived') return false
  if (caseModel.deleted_at !== null) return false
  if (can('administrations.organizations.edit')) return true
  if (caseModel.user_id === stores.user.user.id) return true
  if (can('cases.assign')) return true
  if (caseModel.user_id === null && caseModel.group_id === null) return can('cases.unassigned.update')
  if (caseModel.group_id && stores.user.user.groups.some((group) => group.id === caseModel.group_id)) return true

  return false
}

export function canUseCase(caseModel: CaseModel, policy: string): boolean {
  if (!caseModel) return false
  if (caseModel.deleted_at !== null) return false
  if (caseModel.organization_id !== stores.organization.id) return false
  if (caseModel.user_id === stores.user.user.id) return true
  if (caseModel.user_id === null && caseModel.group_id === null) return true
  if (caseModel.case_users.some((caseUser) => caseUser.user_id === stores.user.user.id)) return true
  if (stores.user.user.groups.length > 0 && caseModel.case_groups.some((caseGroup) => stores.user.user.groups.some((group) => group.id === caseGroup.group_id))) return true

  if (policy === 'organization') return true
  if (policy === 'group') {
    if (caseModel.group_id && stores.user.user.groups.some((group) => group.id === caseModel.group_id)) return true
    if (caseModel.user_id) {
      const caseUserGroupIds = stores.users.users[caseModel.user_id].groups.map((group: Group) => group.id)
      if (stores.user.user.groups.some((group) => caseUserGroupIds.includes(group.id))) return true
    }
  }

  return false
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUser, import.meta.hot))
}
