import { captureException, type ErrorEvent, type Exception, init, setUser } from '@sentry/vue'

import { InMemoryCache } from './cache/InMemoryCache.ts'

interface SentryUser {
  id: number
  email: string
  first_name: string
  last_name: string
}

export const sentry = {
  captureException,
  cache: InMemoryCache.tags('sentry'),
  blacklist: [
    'cancel', // When a user presses the cancel button. The promise is rejected.
    'canceled',
    'CanceledError',
    'close',
    'Load failed',
    'Network Error', // Generic network issues on the user's side.
    'Request failed with status code 500', // Internal server error. The server errors are reported by the backend.
    'Unsaved changes', // When the user tries to navigate away from a page with unsaved changes.
    'Validation failed', // When the user tries to submit a form with invalid data.
    /AbortError/, // AbortController: These are intended and handled.
    /error loading dynamically imported module/, // Happens when the application is updated and the asset hashes change.
    /Failed to fetch/, // AbortController: These are intended and handled.
    /Fetch is aborted/, // AbortController: These are intended and handled.
    /No error message/,
    /Request aborted due to a new request with the same fingerprint/, // AbortController: These are intended and handled.
    /Request failed with status code 4[0-9]{2}/, // Client errors are expected.
    /Unable to preload CSS for/,
    /Unauthorized route/, // When the user tries to access a route they are not authorized to access.
    /user aborted a request/, // AbortController: These are intended and handled.
  ],

  setUser(user: SentryUser) {
    setUser({
      id: `${user.id}`,
      email: user.email,
      'First name': user.first_name,
      'Last name': user.last_name,
    })
  },

  async init() {
    await stores.initialState.load()
    const dsn = $env.sentry.dsn ?? ''
    const environment = $env.app_name

    if (!dsn) {
      return
    }

    init({
      app,
      dsn,
      environment,
      ignoreErrors: [/ResizeObserver loop.*/i, /this.isExpired is not a function/i],
      beforeSend: async (event) => await this.beforeSend(event),
    })
  },

  async beforeSend(event: ErrorEvent) {
    event = this.shouldReport(event)
    event = await this.rateLimit(event)

    if (event.exception?.values?.length === 0) {
      return null
    }

    return event
  },

  shouldReport(event: ErrorEvent) {
    if (event.exception?.values) {
      event.exception.values = event.exception.values.filter((exception) => {
        if (this.blacklist.some((message) => (message instanceof RegExp ? message.test(exception.value as string) : message === exception.value))) {
          //  eslint-disable-next-line no-console
          console.log('Sentry blacklisted:', exception)

          return false
        }

        return true
      })
    }

    return event
  },

  async rateLimit(event: ErrorEvent) {
    if (event.exception?.values) {
      const exceptionValues: Exception[] = []

      for (const exception of event.exception.values) {
        const key = `${exception.type}:${exception.value}`
        if (await this.cache.has(key)) {
          //  eslint-disable-next-line no-console
          console.log('Sentry rate limited:', exception)
        } else {
          this.cache.put(key, '', dayjs().add(1, 'minute'))
          exceptionValues.push(exception)
        }
      }

      event.exception.values = exceptionValues
    }

    return event
  },
}
