import { acceptHMRUpdate, defineStore } from 'pinia'

import { type AtLeast, type User } from '~/types.ts'

export const useUsers = defineStore('users', {
  state: () => ({
    users: {} as Record<number, User>,
    deletedUsers: {} as Record<number, User>,
    cachedUsers: {} as Record<number, User>,
    onlineUsers: new Set() as Set<number>,
  }),

  actions: {
    async update(user: AtLeast<User, 'id'> & { group_ids: number[] }) {
      const { data } = await api.patch<User>(`users/${user.id}`, user)

      if (user.id === stores.user.user.id) {
        stores.user._update(data)
      }

      this._update(data)
    },

    async fetchUserById(id: number) {
      if (id in this.cachedUsers) {
        return this.cachedUsers[id]
      }

      const { data } = await api.get(`users/${id}`)
      this.cachedUsers[id] = data

      return this.cachedUsers[id]
    },

    async forceDelete(id: number) {
      await api.delete(`users/${id}/force`)
      delete this.deletedUsers[id]
    },

    async updateRoles({ id, roles }: { id: number; roles: string[] }) {
      const { data } = await api.patch<User>(`user-roles/${id}`, { roles })

      if (id === stores.user.user.id) {
        stores.user._update(data)
      }

      this._update(data)
    },

    async activate(user: { id: number }) {
      await api.patch(`organization-users/${user.id}`, { active: true })
      this.users[user.id].pivot.active = true
    },

    async updateFunction(id: number | undefined, organizationFunctionId: number | null) {
      if (!id) {
        return
      }

      await api.patch(`organization-users/${id}`, { organization_function_id: organizationFunctionId })
      this.users[id].pivot.organization_function_id = organizationFunctionId
    },

    async deactivate(payload: { id: number; cases_assignee?: number | null; cases_assignee_type?: string | null; remove_cases?: boolean }) {
      await api.post(`deactivate-organization-users/${payload.id}`, payload)
      this.users[payload.id].pivot.active = false
    },

    async remove(payload: { id: number; cases_assignee?: number | null; cases_assignee_type?: string | null; remove_cases: boolean }) {
      const { data } = await api.delete<User>(`users/${payload.id}`, { data: payload })

      const deletedUser = {
        ...this.users[payload.id],
        pivot: data.pivot,
      }
      delete this.users[payload.id]
      this._updateDeletedUsers(deletedUser)
    },

    async restore(id: number) {
      const { data } = await api.post<User>(`users/${id}/restore`)

      delete this.deletedUsers[id]
      this._update(data)
    },

    _updateUsers(users: Record<number, Partial<User>>) {
      for (const [id, user] of Object.entries(users)) {
        this.users[Number(id)] = { ...this.users[Number(id)], ...user }
      }
    },

    _update(users: AtLeast<User, 'id'> | User[]) {
      if (Array.isArray(users)) {
        for (const user of users) {
          this.users[user.id] = user
        }
      } else {
        const user = users
        if (user.id in this.users) {
          this.users[user.id] = { ...this.users[user.id], ...user }
        } else {
          this.users[user.id] = user as User
        }
      }
    },

    set(users: User | User[]) {
      if (Array.isArray(users)) {
        this.users = keyedBy('id', users)
      } else {
        this.users = {
          [users.id]: users,
        }
      }

      app.config.globalProperties.$users = this.users
    },

    setDeleted(users: User | User[]) {
      if (Array.isArray(users)) {
        this.deletedUsers = keyedBy('id', users)
      } else {
        this.deletedUsers = {
          [users.id]: users,
        }
      }
    },

    _updateDeletedUsers(users: AtLeast<User, 'id'> | User[]) {
      if (Array.isArray(users)) {
        for (const user of users) {
          this.deletedUsers[user.id] = user
        }
      } else {
        this.deletedUsers[users.id] = { ...this.deletedUsers[users.id], ...users }
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUsers, import.meta.hot))
}
