import pick from 'lodash-es/pick'
import { acceptHMRUpdate, defineStore } from 'pinia'

import type { OrganizationIntegration } from '~/types.ts'

export const useOrganizationIntegration = defineStore('organizationIntegration', () => {
  const integration = ref<OrganizationIntegration>()
  const loading = ref(false)

  async function fetch() {
    loading.value = true
    try {
      const { data } = await api.get(`organization-integration/${stores.organization.id}`)
      integration.value = data
    } finally {
      loading.value = false
    }
  }

  async function save(keys?: (keyof OrganizationIntegration)[]) {
    let payload: Partial<OrganizationIntegration> = {}

    if (keys?.length) {
      payload = pick(integration.value, keys)
    } else {
      payload = integration.value ?? {}
    }

    const { data } = await api.patch(`organization-integration/${stores.organization.id}`, payload)
    integration.value = data

    $message.success($t('integration-has-been-successfully-saved'))
  }

  return {
    integration,
    loading,
    fetch,
    save,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationIntegration, import.meta.hot))
}
