import { type CaseHit, type CaseHitTransformed, type SearchHit, type SearchHitTransformed } from '~/types.ts'

function vendor(hit: SearchHit) {
  hit.data.vendor = dataSourceVendor(hit.data_source_id)
}

function caseHitInData(hit: SearchHit) {
  hit.data = Object.assign({}, hit.data)
  hit.data.case_hit = hit
}

function caseHitInUpdate(hit: SearchHit & { update: Record<string, unknown> }) {
  hit.update = Object.assign({}, hit.update)
  hit.update.case_hit = hit
}

function hasChanges(hit: SearchHit & { update_changes?: boolean; hasChanges?: boolean }, hitsByDataId: Record<string, CaseHit>) {
  hit.hasChanges = Boolean(hit.update_changes)

  if (hit.data.similar && !hit.data.hasChanges) {
    hit.hasChanges = hit.data.similar?.some((id) => Boolean(hitsByDataId[id]?.update_changes)) || false
  }
}

function hitTransformations<T extends (SearchHit | CaseHit) & { update?: null | Record<string, unknown>; hasChanges?: boolean }>(
  hit: T,
  hitsByDataId: Record<string, CaseHit> | undefined = undefined,
) {
  hit = Object.assign({}, hit)

  caseHitInData(hit)
  vendor(hit)

  if (hit.update) {
    caseHitInUpdate(hit as SearchHit & { update: Record<string, unknown> })
  }

  if (hitsByDataId) {
    hasChanges(hit, hitsByDataId)
  } else {
    hit.hasChanges = false
  }

  return hit as unknown as T extends CaseHit ? CaseHitTransformed : SearchHitTransformed
}

export { hitTransformations }
