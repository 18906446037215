import { acceptHMRUpdate, defineStore } from 'pinia'

import { type Discount } from '~/types.ts'

const partialDefaultDiscount = { id: 0, created_at: '', updated_at: '', deleted_at: null, expires_at: '', starts_at: '' }

export const useDiscounts = defineStore('discounts', () => {
  const discounts = ref<Discount[]>([])
  const loading = ref(false)

  const defaultDiscounts = computed((): Discount[] => [{ ...partialDefaultDiscount, minimum_cost: 39900, discount_percentage: 30, organization_id: stores.organization.id }])

  const activeDiscounts = computed(() =>
    discounts.value.filter((discount) => {
      return dayjs(discount.starts_at).isBefore(dayjs()) && dayjs(discount.expires_at).isAfter(dayjs())
    }),
  )

  async function fetch() {
    loading.value = true
    try {
      const { data } = await api.post('discounts/searches', { organization_id: [stores.organization.id] })
      discounts.value = data
    } finally {
      loading.value = false
    }
  }

  return {
    discounts,
    defaultDiscounts,
    loading,
    activeDiscounts,
    fetch,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDiscounts, import.meta.hot))
}
