import { RequestError } from '@vartion/ui'

import { notifications } from './notifications.ts'

const updateChecker = {
  notified: false,

  async check() {
    if (!stores.user?.authenticated) {
      return
    }

    try {
      const { data } = await api.get('version')

      if (data.version !== $env.version) {
        this.notify()
      }
    } catch (error) {
      if (error instanceof RequestError && error.response?.status === 401) {
        window.location.replace(window.location.origin)
      }
    }
  },

  change() {
    if (!document.hidden) {
      this.check()
    }
  },

  notify() {
    if (this.notified) return

    $notify({
      dangerouslyUseHTMLString: true,
      title: $t('update-available'),
      message: 'An update is available. Click <a href=".">here</a> to update.',
      duration: 0,
      closable: false,
    })

    this.notified = true
  },
}

let listenersCreated = false

function createListeners() {
  if (listenersCreated) {
    return
  }
  listenersCreated = true

  document.addEventListener('visibilitychange', () => updateChecker.change())

  notifications.on('ApplicationUpdated', (notification) => {
    if (notification.data.version !== $env.version) {
      updateChecker.notify()
    }
  })
}

export function useUpdateChecker() {
  createListeners()

  return updateChecker
}

export function onImportError(error: Error) {
  createListeners()
  updateChecker.check()

  throw error
}
