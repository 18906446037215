import { acceptHMRUpdate, defineStore } from 'pinia'

import { type MarketplacePack } from '~/types.ts'

export const useMarketplacePacks = defineStore('marketplacePacks', () => {
  const loading = ref(false)
  const packs = ref<Record<string, MarketplacePack>>({})
  const orderedIds = ref([11, 12, 13, 7, 14, 4, 3, 8, 5, 10, 9] as const)
  const hiddenPackIds = ref([8, 9, 14])

  const activePacks = computed((): MarketplacePack[] => {
    return Object.values(packs.value).filter((pack) => stores.dataSources.dataSources.some((item) => item.active && item.marketplace_pack_id === pack.id))
  })

  const orderedPacks = computed((): MarketplacePack[] => {
    if (Object.keys(packs.value).length === 0) {
      return []
    }

    return orderedIds.value.map((id) => packs.value[id]).filter((pack) => pack)
  })

  async function fetch() {
    if (loading.value || Object.keys(packs.value).length) {
      return
    }

    loading.value = true
    try {
      const { data } = await api.get<MarketplacePack[]>('marketplace-packs')
      packs.value = keyedBy('id', data)
    } finally {
      loading.value = false
    }
  }

  return {
    loading,
    packs,
    orderedIds,
    hiddenPackIds,
    activePacks,
    orderedPacks,
    fetch,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMarketplacePacks, import.meta.hot))
}
