import { type Channel } from 'laravel-echo'

import type { HitComment } from '~/types.ts'
import { notificationsQueue } from './NotificationsQueue.ts'

const queueNotifications = ['CasesAssigned', 'MonitorReportCreated', 'CasesImported', 'UserRequest', 'HitCommentMention']

export interface BaseNotification<T = any> {
  id: string
  title: string
  message: string
  read_at: string | null
  type: string
  created_at: string
  updated_at: string
  data: T
  organization_id?: number
}

export interface ApplicationUpdatedNotification extends BaseNotification {
  type: 'App\\Notifications\\ApplicationUpdated'
  data: { version: string }
}

export interface CasesAssignedNotification extends BaseNotification {
  type: 'App\\Notifications\\CasesAssigned'
  data: {
    ids: string[]
    assigned_to: { id: number; type: 'user' | 'group' }
    assigned_by: number
    case_name: string
  }
}

export interface CasesImportedNotification extends BaseNotification {
  type: 'App\\Notifications\\CasesImported'
  data: {
    imported?: number
    duplicates?: number
  }
}

export interface MonitorReportCreatedNotification extends BaseNotification {
  type: 'App\\Notifications\\MonitorReportCreated'
  data: {
    monitor_report: { id: number }
    has_findings: boolean
    interval: string
  }
}

export interface HitCommentMentionNotification extends BaseNotification {
  type: 'App\\Notifications\\HitCommentMention'
  data: {
    comment: HitComment & { case_name: string }
  }
}

export interface UserRequestNotification extends BaseNotification {
  type: 'App\\Notifications\\UserRequest'
  data: unknown
}

interface NotificationTypeMap {
  ApplicationUpdated: ApplicationUpdatedNotification
  CasesAssigned: CasesAssignedNotification
  CasesImported: CasesImportedNotification
  MonitorReportCreated: MonitorReportCreatedNotification
  HitCommentMention: HitCommentMentionNotification
  UserRequest: UserRequestNotification
}

export type Notification =
  | ApplicationUpdatedNotification
  | CasesAssignedNotification
  | CasesImportedNotification
  | MonitorReportCreatedNotification
  | HitCommentMentionNotification
  | UserRequestNotification

export type Callback<TNotification = Notification> = (notification: TNotification) => void

class Notifications {
  callbacks = {} as Record<keyof NotificationTypeMap, Callback[]>
  allCallbacks = [] as Callback[]

  listen(channel: Channel) {
    channel.notification((notification: Notification) => {
      if (!stores.user.authenticated) {
        return
      }

      if (notification.organization_id && notification.organization_id !== stores.organization.id) {
        return
      }

      const type = notification.type.replace('App\\Notifications\\', '')

      const callbacks = this.callbacks[type as keyof NotificationTypeMap] ?? []
      if (callbacks.length === 0) {
        console.warn(`No callback registered for notification: ${type}`)
      }

      for (const callback of callbacks) {
        callback(notification)
      }

      for (const callback of this.allCallbacks) {
        callback(notification)
      }
    })
  }

  on<TType extends keyof NotificationTypeMap = keyof NotificationTypeMap, TNotification = NotificationTypeMap[TType]>(type: TType, callback: Callback<TNotification>) {
    this.callbacks[type] ??= []
    // @ts-expect-error - callback type should be the same as type.
    this.callbacks[type].push(callback)
  }

  off<TType extends keyof NotificationTypeMap = keyof NotificationTypeMap, TNotification = NotificationTypeMap[TType]>(type: TType, callback: Callback<TNotification>) {
    findAndSpliceArray(this.callbacks[type] ?? [], (cb: Callback) => cb === callback)
  }

  onAll(callback: Callback) {
    this.allCallbacks.push(callback)
  }

  onQueue() {
    this.onAll((notification) => {
      if (queueNotifications.includes(notification.type.replace('App\\Notifications\\', ''))) {
        notificationsQueue.push(notification)
      }
    })
  }

  createMessage(notification: Notification) {
    if (notification.message) {
      return notification.message
    }

    if (notification.type === 'App\\Notifications\\CasesAssigned') {
      const assignedTo = notification.data.assigned_to
      const from = stores.users.users[notification.data.assigned_by]?.name ?? $t('deleted-user')
      let to = ''
      if (assignedTo.type === 'group') {
        to = stores.groups.groups[notification.data.assigned_to.id]?.name ?? $t('deleted-group')
      } else {
        to = stores.users.users[assignedTo.id]?.name ?? $t('deleted-user')
      }

      if (notification.data.ids.length === 1) {
        return $t('the-case-name-was-assigned-to-to-by-from', { from, to, name: notification.data.case_name })
      }

      return $t('count-cases-were-assigned-to-to-by-from', { from, to, count: notification.data.ids.length })
    } else if (notification.type === 'App\\Notifications\\CasesImported') {
      return $t('your-cases-have-been-successfully-imported')
    } else if (notification.type === 'App\\Notifications\\MonitorReportCreated') {
      if (notification.data.has_findings) {
        return $t('a-monitoring-report-was-generated-on-date-with-findings', { date: formatDate(notification.created_at) })
      }

      return $t('a-monitoring-report-was-generated-on-date-without-findings', { date: formatDate(notification.created_at) })
    } else if (notification.type === 'App\\Notifications\\UserRequest') {
      return $t('a-user-approval-request-was-received')
    } else if (notification.type === 'App\\Notifications\\HitCommentMention') {
      const userName = stores.users.users[notification.data.comment.user_id]?.name ?? $t('deleted-user')

      return $t('username-has-mentioned-you-in-case-casename', { userName, caseName: notification.data.comment.case_name })
    }

    return ''
  }

  createTitle(notification: Notification) {
    if (notification.type === 'App\\Notifications\\MonitorReportCreated') {
      return $t('interval-monitoring-report-ready', { interval: $t(notification.data.interval) })
    }

    const type = notification.type.split('\\').pop() ?? ''

    const titles: Record<string, string> = {
      CasesAssigned: $t('cases-assigned'),
      CasesImported: $t('cases-imported'),
      UserRequest: $t('user-account-requested'),
      HitCommentMention: $t('comment-mention'),
    }

    return titles[type] ?? ''
  }
}

export const notifications = new Notifications()
