export const thresholds = {
  'very low': 0,
  low: 0.03,
  moderate: 0.06,
  high: 0.12,
  'very high': 0.24,
}

export const probabilities = {
  'very low': 0.1,
  low: 0.3,
  moderate: 0.5,
  high: 0.7,
  'very high': 0.9,
}

export const impacts = {
  'very low': 0.05,
  low: 0.1,
  moderate: 0.2,
  high: 0.4,
  'very high': 0.8,
}

export function getHighestRisk(risk) {
  if (!risk) {
    return null
  }

  if (risk <= 0.03) {
    return 'very low'
  }
  if (risk <= 0.06) {
    return 'low'
  }
  if (risk <= 0.12) {
    return 'moderate'
  }
  if (risk <= 0.24) {
    return 'high'
  }

  return 'very high'
}

export function riskLabelToThresholdScore(label) {
  for (const [key, value] of Object.entries(thresholds)) {
    if (key === label) {
      return value
    }
  }

  return null
}

export function impactScoreToLabel(impact) {
  for (const [key, value] of Object.entries(impacts)) {
    if (impact <= value) {
      return key
    }
  }

  return 'very high'
}

export function probabilityScoreToLabel(probability) {
  for (const [key, value] of Object.entries(probabilities)) {
    if (probability <= value) {
      return key
    }
  }

  return 'very high'
}
