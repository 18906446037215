import localforage from 'localforage'

import { type CacheRecord, InMemoryCache } from './InMemoryCache.ts'

export class Cache extends InMemoryCache {
  cache = localforage

  constructor({ tags = [] }: { tags?: string[] } = {}) {
    super({ tags })

    // In localforage we can use the prefix to create a unique instance.
    this.cache = localforage.createInstance({ name: this.prefix })
    this.prefix = ''
  }

  static tags(tags: string | string[]) {
    return super.tags(tags) as Cache
  }

  async flush() {
    for (const key of await this.cache.keys()) {
      await this.cache.removeItem(key)
    }
  }

  async flushExpired() {
    for (const key of await this.cache.keys()) {
      const item = (await this.cache.getItem(key)) as string
      if (!item) continue

      const meta = item.split(':')

      if (this.isExpired(meta)) {
        await this.cache.removeItem(key)
      }
    }
  }

  async list() {
    const list: Record<string, string> = {}

    for (const key of await this.cache.keys()) {
      list[key] = (await this.cache.getItem(key)) as string
    }

    return list
  }

  protected async store(key: string, value: string) {
    await this.cache.setItem(key, value)
  }

  async get<T = any>(key: CacheRecord, defaultValue: any = null): Promise<T> {
    key = this.createKey(key)

    const item = (await this.cache.getItem(key)) as string
    if (!item) return defaultValue

    const meta = item.split(':')

    if (this.isExpired(meta)) {
      await this.cache.removeItem(key)

      return defaultValue
    }

    return JSON.parse(this.getValue(meta))
  }

  async forget(key: CacheRecord): Promise<void> {
    await this.cache.removeItem(this.createKey(key))
  }
}
