import throttle from 'lodash-es/throttle.js'
import { acceptHMRUpdate, defineStore } from 'pinia'

interface Event {
  event: string
  category: string
  duration?: number
  caseId?: number
  count?: number
}

async function send() {
  const payload = Object.values(stores.apmEvents.events)
  stores.apmEvents.events = {}
  await api.post('application-metrics', payload)
}

export const useApmEvents = defineStore('apmEvents', {
  state: () => ({
    events: {} as Record<string, Event>,
  }),

  actions: {
    send,
    sendBatch: throttle(send, 10000),

    track(event: Event, immediate = false) {
      if (app.config.globalProperties.$inAdministration || app.config.globalProperties.$inGhostMode) {
        return
      }

      this.events[JSON.stringify(event)] = event

      if (immediate) {
        this.send()
      } else {
        this.sendBatch()
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApmEvents, import.meta.hot))
}
