import { acceptHMRUpdate, defineStore } from 'pinia'

import { type OrganizationPolicy } from '~/types.ts'

export const usePolicies = defineStore('policies', {
  state: () => ({
    policies: {} as OrganizationPolicy,
    defaults: {} as OrganizationPolicy,
    loading: false,
  }),

  actions: {
    load(data: { defaults: OrganizationPolicy }) {
      this.defaults = data.defaults
    },

    async save(policies: { policies: Partial<OrganizationPolicy>; on_policy_change?: string; apply_monitoring_frequency_to_cases?: boolean }) {
      this.loading = true

      try {
        const { data } = await api.patch<OrganizationPolicy>(`organization-policies/${stores.organization.id}`, policies)
        this.policies = data
        this.loading = false
      } catch (error) {
        $message.error($t('api-error'))
        this.loading = false
        throw error
      }
    },

    reset(keys: (keyof OrganizationPolicy)[] = []) {
      for (const key of keys) {
        // @ts-expect-error - keys are the same
        this.policies[key] = this.defaults[key]
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePolicies, import.meta.hot))
}
