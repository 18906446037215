import { acceptHMRUpdate, defineStore } from 'pinia'

import { type AtLeast, type RiskSourceConfig, type RiskSourceConfigWeights } from '~/types.ts'

export const useRiskSourceConfigs = defineStore('riskSourceConfigs', {
  state: () => ({
    configs: {} as Record<number, RiskSourceConfig>,
    saved: {} as Record<number, RiskSourceConfig>,
    defaults: {} as Record<string, RiskSourceConfig>,
  }),

  actions: {
    async load() {
      if (stores.loading.riskSourceConfigs) return
      await stores.loading.update({ riskSourceConfigs: true })

      try {
        const { data: configs } = await api.get<{ data: Record<number, RiskSourceConfig> }>(`risk-source-configs/${stores.organization.id}`)
        const { data: defaults } = await api.get<Record<string, RiskSourceConfig>>('risk-source-configs-defaults')

        this.configs = configs.data
        this.saved = copyObject(configs.data)
        this.defaults = defaults
      } finally {
        await stores.loading.update({ riskSourceConfigs: false })
      }
    },

    async save(riskSourceConfig: AtLeast<RiskSourceConfig, 'id'>) {
      await stores.loading.update({ riskSourceConfigs: true })

      try {
        const { data } = await api.patch<RiskSourceConfig>(`risk-source-configs/${riskSourceConfig.id}`, { ...this.configs[riskSourceConfig.id], ...riskSourceConfig })
        const config = { ...this.configs[data.id], ...data }
        this.configs[data.id] = config
        this.saved[config.id] = copyObject(config)
      } finally {
        await stores.loading.update({ riskSourceConfigs: false })
      }
    },

    updateWeights({ id, weights }: { id: number; weights: RiskSourceConfigWeights }) {
      this.configs[id].weights = { ...this.configs[id].weights, ...weights }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useRiskSourceConfigs, import.meta.hot))
}
