import { acceptHMRUpdate, defineStore } from 'pinia'

import { type ReportSettings, type ReportType } from '~/types.ts'

interface State {
  settings: Record<ReportType, ReportSettings>
}

export const useReportSettings = defineStore('reportSettings', {
  state: (): State => ({
    settings: {
      case: {
        id: 0,
        user_id: 0,
        type: 'case',
        pages: ['overview', 'input', 'policies', 'key_findings', 'audit', 'comments', 'positive', 'negative'],
        detailed: false,
        case_report_pages: null,
        include_case_reports: null,
      },
      cases: {
        id: 0,
        user_id: 0,
        type: 'cases',
        pages: ['overview', 'input', 'policies', 'key_findings', 'audit', 'comments', 'unresolved', 'positive', 'negative'],
        detailed: false,
        case_report_pages: null,
        include_case_reports: null,
      },
      preview: {
        id: 0,
        user_id: 0,
        type: 'preview',
        pages: ['overview', 'input', 'policies', 'key_findings', 'audit', 'comments', 'unresolved'],
        detailed: false,
        case_report_pages: null,
        include_case_reports: null,
      },
      client: {
        id: 0,
        user_id: 0,
        type: 'client',
        pages: ['overview', 'case_overview', 'cases'],
        detailed: false,
        case_report_pages: ['overview', 'input', 'policies', 'key_findings', 'audit', 'comments', 'positive', 'negative', 'passport_verification'],
        include_case_reports: false,
      },
      'user-sessions': {
        id: 0,
        user_id: 0,
        type: 'user-sessions',
        pages: ['overview', 'sessions'],
        detailed: null,
        case_report_pages: null,
        include_case_reports: null,
      },
      'organization-audit': {
        id: 0,
        user_id: 0,
        type: 'organization-audit',
        pages: ['overview', 'audit'],
        detailed: null,
        case_report_pages: null,
        include_case_reports: null,
      },
    },
  }),

  actions: {
    async get() {
      const { data } = await api.get<ReportSettings[]>('report-settings')

      for (const item of data) {
        this.settings[item.type] = item
      }
    },

    async update(reportSettings: ReportSettings) {
      const { data } = await api.post<ReportSettings>('report-settings', reportSettings)

      this.settings[data.type] = data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useReportSettings, import.meta.hot))
}
