import { acceptHMRUpdate, defineStore } from 'pinia'

import { type CaseSourceName, type CaseType } from '~/types.ts'

export const useSource = defineStore('source', {
  state: () => ({
    fields: {} as Record<CaseSourceName, string[]>,
    keyFields: {} as Record<CaseSourceName, string[]>,
    perType: {} as Record<CaseType, CaseSourceName[]>,
    labels: {} as Record<CaseSourceName, string>,
  }),
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSource, import.meta.hot))
}
