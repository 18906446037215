import { acceptHMRUpdate, defineStore } from 'pinia'

import type { OrganizationFunction, SearchResponse } from '~/types.ts'

export const useOrganizationFunctions = defineStore('organizationFunctions', {
  state: () => ({
    functions: {} as Record<number, OrganizationFunction>,
    loading: false,
    loaded: false,
  }),

  actions: {
    async fetch() {
      if (this.loaded || this.loading) {
        return
      }

      await this.refresh()
    },

    async refresh() {
      this.loading = true
      const { data } = await api.post<SearchResponse<OrganizationFunction>>('organization-functions/searches', { organization_id: [stores.organization.id], per_page: 1000 })
      this.functions = keyedBy('id', data.data)
      this.loading = false
      this.loaded = true

      return data
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOrganizationFunctions, import.meta.hot))
}
