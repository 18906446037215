export const formatDate = (value?: null | string | number, defaultDate?: string) => {
  if (value) {
    const date = dayjs(value)

    if (date.isValid()) {
      return date.format(stores.settings.settings.date_format)
    }
  }

  return defaultDate ?? ''
}

export const snake = (string: string) => {
  return string.toLowerCase().replace(/[- ]/g, '_')
}

const search1 = /([a-z0-9])([A-Z])/g
const search2 = /[ _]+/g
const replace1 = '$1-$2'
const replace2 = '-'
export function kebab(string: string) {
  const kebab = string.replace(search1, replace1)

  return kebab.replaceAll(search2, replace2).toLowerCase()
}

export const title = (string: string) => {
  return capitalize(string.replace(/[-_]/g, ' ').toLowerCase())
}

export const camel = (string: string) => {
  const camel = title(string).replaceAll(' ', '')

  return `${camel.charAt(0).toLowerCase()}${camel.substring(1)}`
}

export const formatKey = (string: string) => {
  const key = title(string).toLowerCase()

  return `${key.charAt(0).toUpperCase()}${key.substring(1)}`
}

export const formatKeyValue = (key: string, value: string) => {
  return `${formatKey(key)}: ${value}`
}

export const capitalize = (string: string) => {
  return string
    .toLowerCase()
    .split(' ')
    .map((s) => `${s.charAt(0).toUpperCase()}${s.substring(1)}`)
    .join(' ')
}

export const capitalizeFirst = (string: string) => {
  return `${string.charAt(0).toUpperCase()}${string.substring(1)}`
}

export const cleanString = (string: string) => {
  return string.replace(/\s\s+/g, ' ')
}

export const centsToEuro = (cents: string | number) => {
  const string = `${cents}`

  if (string.length > 2) {
    return `€ ${`${string.slice(0, -2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')},${string.slice(-2)}`}`
  }

  if (string.length === 2) {
    return `€ 0,${string}`
  }

  return `€ 0,0${string}`
}

export const fields: Record<string, string> = {
  'aliases.name': 'aliases',
  'case_hit.created_at': 'case-hit-created-at',
  'controlling_entity.name': 'controlling-entity-name',
  'nationalities.country': 'nationalities-country',
  asset_type: 'type',
}

export function mapField(field: string): string {
  if (!field) {
    return field
  }

  if (field in fields) {
    return $t(fields[field])
  }

  const kebabField = kebab(field)

  // If the key exists in the en-gb translation, return the translated variant.
  // We check on en-gb specifically because other languages can have missing keys.
  if ($te(kebabField, 'en-gb')) {
    return $t(kebabField)
  }

  return formatKey(kebabField)
}

export function avatarPlaceholder(text: string) {
  return text
    .split(' ')
    .slice(0, 3)
    .map((word) => word[0])
    .join('')
    .toUpperCase()
}
