import chunk from 'lodash-es/chunk.js'
import throttle from 'lodash-es/throttle.js'

import { type CaseType } from '~/types.ts'

export interface Check {
  sanctions: number
  peps: number
  enforcements: number
}

export const nameChecker = {
  checking: false,
  queue: {
    Person: new Set<string>(),
    Business: new Set<string>(),
  },
  cache: {
    Person: {} as Record<string, Check>,
    Business: {} as Record<string, Check>,
  },
  get: undefined as unknown as ReturnType<typeof throttle>,

  async check(name: string, type: CaseType | ''): Promise<Check> {
    name = name.trim()

    if (type !== 'Person' && type !== 'Business') {
      await Promise.all([this.check(name, 'Person'), this.check(name, 'Business')])
      if (Object.values(this.cache.Person[name]).some((score) => score > 0)) {
        return this.cache.Person[name]
      }

      return this.cache.Business[name]
    }

    if (name in this.cache[type]) {
      return this.cache[type][name]
    }

    this.queue[type].add(name)

    this.get()

    while (!(name in this.cache[type])) {
      await nextFrame()
    }

    return this.cache[type][name]
  },

  async _get() {
    while (this.checking) await nextFrame()

    const queue = this.queue
    this.resetQueue()
    this.checking = true

    try {
      let type: 'Person' | 'Business'
      for (type in queue) {
        if (queue[type].size === 0) continue

        for (const names of chunk(Array.from(queue[type]), 3)) {
          const { data } = await api.post<Record<string, Check>>('check-names', { form: { names, type } })

          for (const name in data) {
            this.cache[type][name] = data[name]
          }
        }
      }
    } finally {
      this.checking = false
    }
  },

  resetQueue() {
    this.queue = {
      Person: new Set(),
      Business: new Set(),
    }
  },
}

nameChecker.get = throttle(nameChecker._get, 100, { leading: false })
