import { Cache } from '~/services/cache/Cache.ts'

const element = ref<HTMLElement | null>(null)

let initialized = false
let isAuthenticated = false
let widgetIsLoaded = false
const isReady = ref(false)

/**
 * Identifies the user if they are authenticated.
 * Loads the widget afterwards.
 */
async function identify() {
  if (!stores.user.authenticated) {
    load()

    return
  }

  const user = stores.user.user

  const token = await Cache.tags(`user:${user.id}`).remember('hubspot-visitor-identification-token', dayjs().add(12, 'hours'), async () => {
    const { data } = await api.post<{ token: string }>('hubspot-visitor-identification-tokens')

    return data.token
  })

  window.hsConversationsSettings = {
    identificationEmail: user.email,
    identificationToken: token,
  }

  if (widgetIsLoaded) {
    window.HubSpotConversations.resetAndReloadWidget()
  } else {
    load()
  }
  isAuthenticated = true
}

/**
 * Loads the widget.
 */
function load() {
  window.HubSpotConversations.widget.load()
  widgetIsLoaded = true
}

/**
 * Shows the widget.
 */
async function show() {
  if (!isAuthenticated && stores.user.authenticated) {
    await identify()
  }
  window.HubSpotConversations.widget.open()
}

/**
 * Closes the widget if it is opened.
 */
function close() {
  window.HubSpotConversations.widget.close()
}

/**
 * Removes the widget from the screen. You need to call load() again to make it appear.
 */
function remove() {
  window.HubSpotConversations?.widget?.remove()
}

/**
 * Initializes the widget by loading the script and setting a ref to true when it is ready.
 */
function init() {
  if (initialized) {
    return
  }

  window.hsConversationsSettings = {
    loadImmediately: false,
  }

  const script = document.createElement('script')
  script.src = '//js-na1.hs-scripts.com/9132146.js'
  script.id = 'hs-script-loader'

  document.getElementsByTagName('head')[0].appendChild(script)
  element.value = script

  window.hsConversationsOnReady = [() => (isReady.value = true)]

  initialized = true
}

/**
 * Use the hubspot livechat.
 *
 * @param loadOnMounted: Load the chat widget when the component gets mounted.
 */
export function useLivechat({ loadOnMounted }: { loadOnMounted: boolean } = { loadOnMounted: true }) {
  init()

  if (loadOnMounted) {
    onMounted(() => {
      watch(
        isReady,
        (value) => {
          if (value) {
            identify()
          }
        },
        { immediate: true },
      )
    })
    onUnmounted(() => remove())
  }

  return { show, close }
}
